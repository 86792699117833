import { Spinner } from '@material-tailwind/react'
import { useEffect, useRef } from 'react'

import ChatMessage from './ChatMessage'
import { ChatHandler } from './chat.interface'

const ChatMessages = (props: Pick<ChatHandler, 'messages' | 'isLoading' | 'reload' | 'stop' | 'append'>) => {
  const messageLength = props.messages.length
  const lastMessage = props.messages[messageLength - 1]
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const isLastMessageFromAssistant = messageLength > 0 && lastMessage?.role !== 'user'
  const isPending = props.isLoading && !isLastMessageFromAssistant
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [props.messages, props.isLoading])
  return (
    <div id="ava-sidebar-content" className="text-gray-900 text-base mb-3 md:mb-6 relative">
      <div className="space-y-1 h-full">
        <div>
          {props.messages.map((m, i) => {
            const isLoadingMessage = i === messageLength - 1 && props.isLoading
            return <ChatMessage key={m.id} chatMessage={m} isLoading={isLoadingMessage} />
          })}
          {isPending && <Spinner className="mx-auto h-5 w-5 text-white" />}
          <div ref={messagesEndRef} />
        </div>
      </div>
    </div>
  )
}
export default ChatMessages
