'use client'

import { LightBulbIcon } from '@heroicons/react/24/outline'
import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { useEffect, useMemo, useState } from 'react'

import avaIcon from '@/public/images/ava-icon.png'

import { UserRoles } from '@roadmap/lib/services/firebase/firestore/enums'

import { generatePrompts } from '../Chat/chat.utils'

interface Card {
  text: string
  onClick: () => void
}

interface AvaContentProps {
  onCardSelected: (cardContent: string) => void
  isOpen: boolean
  generationComplete: any
  setGenerationComplete: any
}

const PromptCard = (card: Card) => (
  <div
    onClick={card.onClick}
    className="flex sm:flex-col items-center p-2.5 sm:p-4 border rounded-2xl shadow-lg bg-white cursor-pointer"
  >
    <div className="w-8 h-8 sm:mb-2 mr-2 sm:mr-0">
      <LightBulbIcon className="h-7 w-7" />
    </div>
    <p className="leading-5 sm:leading-6">{card.text}</p>
  </div>
)

const AvaInitialState = ({ onCardSelected, isOpen, generationComplete, setGenerationComplete }: AvaContentProps) => {
  const t = useTranslations('AskAva')
  const tStudent = useTranslations('AVAStudentPrompts')
  const tParent = useTranslations('AVAParentPrompts')
  const tCounselor = useTranslations('AVACounselorPrompts')
  const avaIntro = t('avaIntro')
  const suggestedPromtsIntro = t('suggestedPromtsIntro')

  const { data } = useSession()
  const generatedCards = useMemo(() => {
    if (data && data.user && data.user.role) {
      const { role } = data.user
      switch (role) {
        case UserRoles.COUNSELOR:
          return generatePrompts(tCounselor)
        case UserRoles.STUDENT:
          return generatePrompts(tStudent)
        case UserRoles.FAMILY:
          return generatePrompts(tParent)
      }
    }
    return []
  }, [data, tCounselor, tParent, tStudent])

  const [introText, setIntroText] = useState('')
  const [index, setIndex] = useState(0)
  const [cardsDisplayed, setCardsDisplayed] = useState(false)
  const [cardsToDisplay, setCardsToDisplay] = useState<string[]>([])
  const [suggestedPromtsText, setSuggestedPromtsText] = useState('')
  const [suggestedIndex, setSuggestedIndex] = useState(0)

  const speed = 5
  const suggestedPromtsSpeed = 5
  const cardDisplaySpeed = 500

  const handleCardClick = (text: string) => {
    onCardSelected(text)
  }

  useEffect(() => {
    if (generationComplete) {
      setIntroText(avaIntro)
      return
    }
    if (isOpen && index < avaIntro.length) {
      const timeout = setTimeout(() => {
        setIntroText(introText + avaIntro.charAt(index))
        setIndex(index + 1)
      }, speed)

      return () => clearTimeout(timeout)
    } else if (index >= avaIntro.length && !cardsDisplayed) {
      setCardsDisplayed(true)
    }
  }, [index, isOpen, introText, cardsDisplayed, generationComplete])

  useEffect(() => {
    if (generationComplete) {
      setSuggestedPromtsText(suggestedPromtsIntro)
      return
    }

    if (cardsDisplayed && suggestedIndex < suggestedPromtsIntro.length) {
      const timeout = setTimeout(() => {
        setSuggestedPromtsText(suggestedPromtsText + suggestedPromtsIntro.charAt(suggestedIndex))
        setSuggestedIndex(suggestedIndex + 1)
      }, suggestedPromtsSpeed)

      return () => clearTimeout(timeout)
    }
  }, [cardsDisplayed, suggestedPromtsIntro, suggestedIndex, generationComplete])

  useEffect(() => {
    if (cardsDisplayed && cardsToDisplay.length < generatedCards.length) {
      const timeout = setTimeout(() => {
        setCardsToDisplay((prev) => [...prev, generatedCards[cardsToDisplay.length].text])

        if (cardsToDisplay.length + 1 == generatedCards.length) {
          setGenerationComplete(true)
        }
      }, cardDisplaySpeed)

      return () => clearTimeout(timeout)
    }
  }, [cardsDisplayed, cardsToDisplay, generatedCards, generationComplete])

  useEffect(() => {
    if (generationComplete) {
      setCardsToDisplay(generatedCards.map((card) => card.text))
      return
    }
  }, [])

  return (
    <div id="ava-sidebar-content" className="p-2 md:p-4">
      <div className="flex items-center space-x-2">
        <div className="relative w-[28px] h-[45px]">
          <Image
            src={avaIcon}
            alt="Ava Icon"
            fill
            style={{ objectFit: 'contain' }}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vwvw, 100vw"
          />
        </div>
        <h1 className="text-xl font-bold text-gray-900">{t('askAva')}</h1>
      </div>

      <div className="text-gray-900 mt-4 md:mt-8">{introText}</div>

      {cardsDisplayed && (
        <div className="text-gray-900 mt-3 md:mt-6">
          <p>{suggestedPromtsText}</p>
        </div>
      )}

      {/* Prompt cards */}
      {generationComplete || cardsToDisplay.length > 0 ? (
        <div className="text-gray-900">
          <div className="container mx-auto p-2 sm:p-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {generatedCards
                .filter((_, index) => cardsToDisplay.includes(generatedCards[index].text))
                .map((card, index) => (
                  <PromptCard key={index} text={card.text} onClick={() => handleCardClick(card.text)} />
                ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default AvaInitialState
