import { UserCircleIcon } from '@heroicons/react/24/solid'
import { useTranslations } from 'next-intl'
import Image from 'next/image'

import avaIcon from '@/public/images/ava-icon.png'

const ChatAvatar = ({ role }: { role: string }) => {
  const t = useTranslations('AskAva')

  if (role === 'user') {
    return (
      <div className="flex flex-col items-center self-start">
        <UserCircleIcon className="w-8 h-8" />
        <span className="text-xs mt-1">{t('you')}</span>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center self-start">
      <div className="relative w-8 h-8">
        <Image
          src={avaIcon}
          alt="Eva Icon"
          fill
          style={{ objectFit: 'contain' }}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vwvw, 100vw"
        />
      </div>
      <span className="text-xs mt-1">Eva</span>
    </div>
  )
}

export default ChatAvatar
