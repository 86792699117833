/* eslint-disable */
import 'katex/dist/katex.min.css'
import { FC, memo } from 'react'
import ReactMarkdown, { Options } from 'react-markdown'
import rehypeExternalLinks from 'rehype-external-links'
import rehypeKatex from 'rehype-katex'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'

const MemoizedReactMarkdown: FC<Options> = memo(
  ReactMarkdown,
  (prevProps, nextProps) => prevProps.children === nextProps.children && prevProps.className === nextProps.className
)

function containsDollar(sentence: string) {
  const currencySymbol = '\\$'
  const regex = new RegExp(`${currencySymbol}\\d+`)
  return regex.test(sentence)
}

const preprocessLaTeX = (content: string) => {
  if (containsDollar(content)) {
    let processedContent = content.replace(/\$(\d+)/g, '&#36;$1')
    return processedContent
  } else {
    const blockProcessedContent = content.replace(/\\\[(.*?)\\\]/gs, (_, equation) => `$$${equation}$$`)
    const inlineProcessedContent = blockProcessedContent.replace(/\\\((.*?)\\\)/gs, (_, equation) => `$${equation}$`)
    return inlineProcessedContent
  }
}

const Markdown = ({ content }: { content: string }) => {
  const processedContent = preprocessLaTeX(content)
  return (
    <MemoizedReactMarkdown
      className="prose dark:prose-invert prose-p:leading-relaxed prose-pre:p-0 break-words custom-markdown"
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeKatex as any, [rehypeExternalLinks, { target: '_blank' }]]}
      components={{
        p({ children }) {
          return <p className="mb-2 last:mb-0">{children}</p>
        },
        a({ href, children }) {
          return (
            <a
              href={href}
              className="text-blue-600 underline hover:text-blue-800 visited:text-purple-600"
              target="_blank"
              rel="noopener noreferrer"
            >
              {children}
            </a>
          )
        },
      }}
    >
      {processedContent}
    </MemoizedReactMarkdown>
  )
}

export default Markdown
