import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import { BackspaceIcon } from '@heroicons/react/24/solid'
import { Tooltip } from '@material-tailwind/react'
import { useTranslations } from 'next-intl'
import { useState } from 'react'
import { UseFormRegister, UseFormSetValue } from 'react-hook-form'

import { TextQuestion } from '@/components/Chat/chat.utils'

interface Props {
  textQuestion: TextQuestion
  register: UseFormRegister<any>
  setValue: UseFormSetValue<any> // To handle form state updates
}

const TextQuestionComponent = ({ textQuestion, register, setValue }: Props) => {
  const [shouldShowGuidance, setShouldShowGuidance] = useState(false)
  const t = useTranslations('CounselorRecLetter')

  return (
    <div key={textQuestion.id} className="mb-3">
      <p className="font-bold">{textQuestion.label}</p>
      <button
        type="button"
        className="flex flex-row items-center gap-x-0.5 mb-2"
        onClick={() => setShouldShowGuidance(!shouldShowGuidance)}
      >
        {!shouldShowGuidance ? (
          <PlusCircleIcon height={20} width={20} className="stroke-brand-purple hover:underline" />
        ) : (
          <MinusCircleIcon height={20} width={20} className="stroke-brand-purple hover:underline" />
        )}
        <p className="text-brand-purple">Tips</p>
      </button>
      <div
        className={`max-h-0 opacity-0 transition-all duration-300 ease-in-out ${
          shouldShowGuidance ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
        } overflow-hidden`}
      >
        <ul className="list-disc pl-5">
          {textQuestion.guidanceBullets.map((bullet) => {
            if (bullet.id.includes('no_bullet'))
              return (
                <p className="-ml-5" key={bullet.id}>
                  {bullet.label}
                </p>
              )
            return <li key={bullet.id}>{bullet.label}</li>
          })}
        </ul>
      </div>

      <div className="relative">
        <div className="flex justify-end">
          <Tooltip content={t('clear')} placement="top">
            <BackspaceIcon
              className="h-6 w-6 text-brand-purple cursor-pointer hover:text-red-500"
              onClick={() => setValue(textQuestion.id, '')} // Clear the text area
            />
          </Tooltip>
        </div>
      </div>

      <textarea
        className="w-full rounded-md border-gray-300 bg-gray-50 text-brand-purple  focus:ring-brand-purple  focus:border-brand-purple   h-44"
        {...register(textQuestion.id)}
      />
    </div>
  )
}

export default TextQuestionComponent
