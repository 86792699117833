'use client'

import {
  ArrowPathIcon,
  ClipboardIcon,
  DocumentDuplicateIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from '@heroicons/react/24/outline'
import { Tooltip } from '@material-tailwind/react'
import { useTranslations } from 'next-intl'
import { useState } from 'react'

import { useChatbot } from '@/providers/ChatbotProvider'

const RecLetterResponseState = () => {
  const t = useTranslations('AskAva')
  const tRec = useTranslations('CounselorRecLetter')
  const tError = useTranslations('Errors')

  const [copySuccess, setCopySuccess] = useState(false)

  const { recLetterResponse: response } = useChatbot()
  if (!response) return <p>{tError('unknownErrorTryAgain')}</p>

  const handleCopyToClipboard = () => {
    const textToCopy = response

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopySuccess(true)
        setTimeout(() => setCopySuccess(false), 2000) // Hide success message after 2 seconds
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }
  return (
    <div className="w-full h-max flex flex-col pb-14 px-9">
      <p className="mt-4 mb-5">{tRec('generatedRecLetter')}</p>
      <p className=" whitespace-pre-wrap mb-10">{response}</p>

      <div className="flex space-x-2 mt-4 ">
        <button disabled className="relative group hover:shadow-none shadow-none rounded-full" onClick={() => {}}>
          <HandThumbUpIcon className="w-6 h-6 text-gray-500 " />
        </button>
        <button disabled className="relative group" onClick={() => {}}>
          <HandThumbDownIcon className="w-6 h-6 text-gray-500 " />
        </button>
        <button className="relative group" disabled onClick={() => {}}>
          <DocumentDuplicateIcon className="w-6 h-6 text-gray-500 " />
        </button>
        <button className="relative group" onClick={() => handleCopyToClipboard()}>
          <Tooltip content={t('copy')} position="top" className="py-2">
            <ClipboardIcon className="w-6 h-6 text-brand-purple " />
          </Tooltip>
        </button>
        <button className="relative group" disabled onClick={() => {}}>
          <ArrowPathIcon className="w-6 h-6 text-gray-500 " />
        </button>
      </div>
      {/* Copy success message */}
      {copySuccess && <div className="mt-2 text-green-600 text-sm">{tRec('recLetterCopied')}</div>}
    </div>
  )
}

export default RecLetterResponseState
