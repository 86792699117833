import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useTranslations } from 'next-intl'

const ReachedLimitState = () => {
  const t = useTranslations('AskAva')
  return (
    <div className="w-full h-full flex grow flex-col justify-center items-center">
      <ExclamationTriangleIcon className="h-10 w-10 mb-5" />
      <p>{t('reachedLimit')}</p>
    </div>
  )
}

export default ReachedLimitState
